import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../Styles/global.css";

export default function OurTeam() {
  return (
    <>
      <Header showNav1={false} />
      <div>
        <section className="mt-15 mb-15">
          <div className="container">
            <div className="service-header">
              <h1>
                Our <span style={{ color: "#AC2027" }}>Team</span>
              </h1>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="our-team-list">
              <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/BiswaPanda.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Biswa Panda</h4>
                      <h5>CEO</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/SanujMalik.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Sanuj Malik</h4>
                      <h5>VP-Business Growth</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/Snigdha.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Snigdharani Mohanty</h4>
                      <h5>COO</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/Prasad.png" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Prasad Tambe</h4>
                      <h5>Business Manager - West</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-15">
                
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/Saurabh.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Saurabh Meena</h4>
                      <h5>VP- Igaming</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/AkhilSharma.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Akhil Sharma</h4>
                      <h5>Media Buyer</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/Rachna.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Rachna Mishra</h4>
                      <h5>Digital Ad Sales</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/NidhiSuman.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Nidhi Suman</h4>
                      <h5>SEO Manager</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-15">
                
                
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/DeeptiBhatia.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Deepti Bhatia</h4>
                      <h5>Delivery Manger</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/NiharikaShrimal.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Niharika Samal</h4>
                      <h5>Account Executive</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/ManukiranPR.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Manukiran P R</h4>
                      <h5>Publisher Account Manager</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="team">
                    <div className="team-img">
                      <img src="images/ourteam/Eashwari.jpg" />
                    </div>
                    <div className="team-deatils mt-3">
                      <h4>Lokeshwari</h4>
                      <h5>Operations Executive</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-15">
                
                
                
                
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
